import React, { useState } from "react";
import { Card, Row, Col, Menu, Divider, Button, Table, Form, Input, DatePicker, Radio } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const DetailPaymentReport: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("paymentType");
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);

    setDateRange([
      dayjs(values?.startDate)?.format("DD-MM-YYYY"),
      dayjs(values?.endDate)?.format("DD-MM-YYYY"),
    ]);

    // Set dummy report data
    setReportData([
      {
        key: 1,
        date: "10-10-2024",
        paymentType: "Credit Card",
        refNo: "REF001",
        name: "John Doe",
        gstPaid: "$20",
        payment: "$200",
        consult: "Consult A",
        spec: "Spec 1",
      },
      {
        key: 2,
        date: "11-10-2024",
        paymentType: "Cash",
        refNo: "REF002",
        name: "Jane Doe",
        gstPaid: "$15",
        payment: "$150",
        consult: "Consult B",
        spec: "Spec 2",
      },
      // Add more rows as needed
    ]);
  };

  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Payment Type", dataIndex: "paymentType", key: "paymentType" },
    { title: "Ref No", dataIndex: "refNo", key: "refNo" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "GST Paid", dataIndex: "gstPaid", key: "gstPaid" },
    { title: "Payment", dataIndex: "payment", key: "payment" },
    { title: "Consult", dataIndex: "consult", key: "consult" },
    { title: "Spec", dataIndex: "spec", key: "spec" },
  ];

  const renderFormAndTable = (title: string) => (
    <Card style={{ minHeight: "calc(100vh - 138px)", overflowY: "auto" }}>
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        {title}
      </Divider>
      <Form layout="vertical" onFinish={handleFinish}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Required" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "Required" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Optometrist"
              name="optometrist"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Optometrist Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Report Destination" name="reportDestination">
              <Radio.Group>
                <Radio value="screen">To Screen</Radio>
                <Radio value="printer">To Printer</Radio>
                <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <div>
            <Button>Reset</Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "34px", marginLeft: "12px" }}
            >
              Generate Report
            </Button>
          </div>
        </Row>
      </Form>
      {reportData.length > 0 && (
        <>
          <Divider orientation="left" orientationMargin="0">
            Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </>
      )}
    </Card>
  );

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item key="paymentType">Detail Payment Report By Payment Type</Menu.Item>
            <Menu.Item key="provider">Detail Payment Report By Provider</Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>
        {selectedKey === "paymentType" &&
          renderFormAndTable("Detail Payment Report By Payment Type")}
        {selectedKey === "provider" && renderFormAndTable("Detail Payment Report By Provider")}
      </Col>
    </Row>
  );
};

export default DetailPaymentReport;
