import { Button, Card, Col, DatePicker, Divider, Form, Radio, Row } from "antd";
import React from "react";

const DeletedBillingPaymentReport = () => {
  const handleFinish = () => {};
  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Deleted Billing / Payment Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default DeletedBillingPaymentReport;
