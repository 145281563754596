import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Input, Button, DatePicker, Table, Radio } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const ExpensePaymentReport: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  // Handle form submission
  const handleFinish = (values: any) => {
    console.log("Report values: ", values);

    // Set the formatted date range
    setDateRange([
      dayjs(values?.startDate)?.format("DD-MM-YYYY"),
      dayjs(values?.endDate)?.format("DD-MM-YYYY"),
    ]);

    // Dummy data for Expense Payment Report
    setReportData([
      {
        key: 1,
        date: "10-10-2024",
        chequeNumber: "CHQ001",
        supplier: "Supplier A",
        supplierRef: "SUPREF001",
        category: "Category 1",
        description: "Expense Description 1",
        nonTaxableAmount: "$100",
        taxableAmount: "$200",
        gst: "$30",
        totalInclusiveTax: "$330",
      },
      {
        key: 2,
        date: "11-10-2024",
        chequeNumber: "CHQ002",
        supplier: "Supplier B",
        supplierRef: "SUPREF002",
        category: "Category 2",
        description: "Expense Description 2",
        nonTaxableAmount: "$150",
        taxableAmount: "$250",
        gst: "$40",
        totalInclusiveTax: "$440",
      },
      {
        key: 3,
        date: "12-10-2024",
        chequeNumber: "CHQ003",
        supplier: "Supplier C",
        supplierRef: "SUPREF003",
        category: "Category 3",
        description: "Expense Description 3",
        nonTaxableAmount: "$200",
        taxableAmount: "$300",
        gst: "$50",
        totalInclusiveTax: "$550",
      },
    ]);
  };

  // Define the table columns
  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Cheque Number", dataIndex: "chequeNumber", key: "chequeNumber" },
    { title: "Supplier", dataIndex: "supplier", key: "supplier" },
    { title: "Supplier Ref", dataIndex: "supplierRef", key: "supplierRef" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Non-Taxable Amount", dataIndex: "nonTaxableAmount", key: "nonTaxableAmount" },
    { title: "Taxable Amount", dataIndex: "taxableAmount", key: "taxableAmount" },
    { title: "GST", dataIndex: "gst", key: "gst" },
    { title: "Total Inclusive Tax", dataIndex: "totalInclusiveTax", key: "totalInclusiveTax" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Expense Payment Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Supplier Code"
                name="supplierCode"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Supplier Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Expense Group"
                name="expenseGroup"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Expense Group" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Cheque Number" name="chequeNumber">
                <Input placeholder="Enter Cheque Number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Report Type"
                name="reportType"
                rules={[{ required: true, message: "Required" }]}
              >
                <Radio.Group>
                  <Radio value="detailByDate">Detail Report by Date</Radio>
                  <Radio value="detailByGroup">Detail Report by Group</Radio>
                  <Radio value="detailByChequeNumber">Detail Report by Cheque Number</Radio>
                  <Radio value="summaryByGroup">Summary Report by Group</Radio>
                  <Radio value="summaryByChequeNumber">Summary Report by Cheque Number</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Expense Payment Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default ExpensePaymentReport;
