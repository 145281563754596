import React, { useState } from "react";
import { Row, Col, Card, Menu, Descriptions, Table, Button, Divider, Typography } from "antd";
import { FaTrashAlt } from "react-icons/fa";

const BillingDetails: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("consultation-bill");

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const invoiceItems = [
    { key: 1, sn: 1, test: "Blood Test", price: "500" },
    { key: 2, sn: 2, test: "X-Ray", price: "1200" },
    { key: 3, sn: 3, test: "MRI", price: "3000" },
  ];

  const columns: any = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
      width: 50,
    },
    {
      title: "Test",
      dataIndex: "test",
      key: "test",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
    },
    {
      title: "Action",
      key: "action",
      render: () => <Button type="text" icon={<FaTrashAlt style={{ color: "#FF4D4F" }} />} />,
      align: "center",
    },
  ];

  const renderContent = () => {
    switch (selectedKey) {
      case "consultation-bill":
        return (
          <Card>
            <Row gutter={8}>
              <Col span={16}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography.Title level={4}>Consultation Billing Details</Typography.Title>
                      <Typography.Text type="secondary">
                        Review your invoice details here
                      </Typography.Text>
                    </div>
                    <Button type="primary">View Invoice</Button>
                  </div>

                  <Card
                    style={{
                      border: "2px dotted #9CA3AF",
                      backgroundColor: "#F3F4F6",
                      marginTop: 24,
                      borderStyle: "dotted",
                      borderWidth: "2px",
                      borderColor: "#9CA3AF",
                    }}
                  >
                    <Descriptions
                      layout="horizontal"
                      column={2}
                      labelStyle={{ fontWeight: "bold", color: "black" }}
                    >
                      <Descriptions.Item label="Phone Number">09xxxxxxxxx</Descriptions.Item>
                      <Descriptions.Item label="Email">example@example.com</Descriptions.Item>
                      <Descriptions.Item label="Gender">Male</Descriptions.Item>
                      <Descriptions.Item label="Date of Birth">20/12/1980</Descriptions.Item>
                      <Descriptions.Item label="Nationality">Nepali</Descriptions.Item>
                      <Descriptions.Item label="Address">Kathmandu, Nepal</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Table
                    columns={columns}
                    dataSource={invoiceItems}
                    pagination={false}
                    style={{ marginTop: 24 }}
                  />
                </Card>
              </Col>

              <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
                <Card
                  style={{
                    flex: 1,
                  }}
                >
                  <Typography.Title level={4}>Summary</Typography.Title>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24 }}>
                    <Typography.Text strong>Test Subtotal</Typography.Text>
                    <Typography.Text strong>₹4700</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text>Consultation Charges</Typography.Text>
                    <Typography.Text>₹500</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text>Discounts</Typography.Text>
                    <Typography.Text>-₹200</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text strong>Total Bill</Typography.Text>
                    <Typography.Text strong>₹5000</Typography.Text>
                  </div>
                  <Divider />
                  <Button type="primary" block style={{ marginBottom: 16 }}>
                    Finalize Billing
                  </Button>
                  <Button block>Email Invoice</Button>
                </Card>
              </Col>
            </Row>
          </Card>
        );
      case "spectacle-bill":
        return (
          <Card>
            <Row gutter={8}>
              <Col span={16}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography.Title level={4}>Spectacle Billing Details</Typography.Title>
                      <Typography.Text type="secondary">
                        Review your invoice details here
                      </Typography.Text>
                    </div>
                    <Button type="primary">View Invoice</Button>
                  </div>

                  <Card
                    style={{
                      border: "2px dotted #9CA3AF",
                      backgroundColor: "#F3F4F6",
                      marginTop: 24,
                      borderStyle: "dotted",
                      borderWidth: "2px",
                      borderColor: "#9CA3AF",
                    }}
                  >
                    <Descriptions
                      layout="horizontal"
                      column={2}
                      labelStyle={{ fontWeight: "bold", color: "black" }}
                    >
                      <Descriptions.Item label="Phone Number">09xxxxxxxxx</Descriptions.Item>
                      <Descriptions.Item label="Email">example@example.com</Descriptions.Item>
                      <Descriptions.Item label="Gender">Male</Descriptions.Item>
                      <Descriptions.Item label="Date of Birth">20/12/1980</Descriptions.Item>
                      <Descriptions.Item label="Nationality">Nepali</Descriptions.Item>
                      <Descriptions.Item label="Address">Kathmandu, Nepal</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Table
                    columns={columns}
                    dataSource={invoiceItems}
                    pagination={false}
                    style={{ marginTop: 24 }}
                  />
                </Card>
              </Col>

              <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
                <Card
                  style={{
                    flex: 1,
                  }}
                >
                  <Typography.Title level={4}>Summary</Typography.Title>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24 }}>
                    <Typography.Text strong>Test Subtotal</Typography.Text>
                    <Typography.Text strong>₹4700</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text>Consultation Charges</Typography.Text>
                    <Typography.Text>₹500</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text>Discounts</Typography.Text>
                    <Typography.Text>-₹200</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text strong>Total Bill</Typography.Text>
                    <Typography.Text strong>₹5000</Typography.Text>
                  </div>
                  <Divider />
                  <Button type="primary" block style={{ marginBottom: 16 }}>
                    Finalize Billing
                  </Button>
                  <Button block>Email Invoice</Button>
                </Card>
              </Col>
            </Row>
          </Card>
        );
      case "contact-lens-bill":
        return (
          <Card>
            <Row gutter={8}>
              <Col span={16}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography.Title level={4}>Contact Lens Billing Details</Typography.Title>
                      <Typography.Text type="secondary">
                        Review your invoice details here
                      </Typography.Text>
                    </div>
                    <Button type="primary">View Invoice</Button>
                  </div>

                  <Card
                    style={{
                      border: "2px dotted #9CA3AF",
                      backgroundColor: "#F3F4F6",
                      marginTop: 24,
                      borderStyle: "dotted",
                      borderWidth: "2px",
                      borderColor: "#9CA3AF",
                    }}
                  >
                    <Descriptions
                      layout="horizontal"
                      column={2}
                      labelStyle={{ fontWeight: "bold", color: "black" }}
                    >
                      <Descriptions.Item label="Phone Number">09xxxxxxxxx</Descriptions.Item>
                      <Descriptions.Item label="Email">example@example.com</Descriptions.Item>
                      <Descriptions.Item label="Gender">Male</Descriptions.Item>
                      <Descriptions.Item label="Date of Birth">20/12/1980</Descriptions.Item>
                      <Descriptions.Item label="Nationality">Nepali</Descriptions.Item>
                      <Descriptions.Item label="Address">Kathmandu, Nepal</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Table
                    columns={columns}
                    dataSource={invoiceItems}
                    pagination={false}
                    style={{ marginTop: 24 }}
                  />
                </Card>
              </Col>

              <Col span={8} style={{ display: "flex", flexDirection: "column" }}>
                <Card
                  style={{
                    flex: 1,
                  }}
                >
                  <Typography.Title level={4}>Summary</Typography.Title>
                  <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24 }}>
                    <Typography.Text strong>Test Subtotal</Typography.Text>
                    <Typography.Text strong>₹4700</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text>Consultation Charges</Typography.Text>
                    <Typography.Text>₹500</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text>Discounts</Typography.Text>
                    <Typography.Text>-₹200</Typography.Text>
                  </div>
                  <Divider />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography.Text strong>Total Bill</Typography.Text>
                    <Typography.Text strong>₹5000</Typography.Text>
                  </div>
                  <Divider />
                  <Button type="primary" block style={{ marginBottom: 16 }}>
                    Finalize Billing
                  </Button>
                  <Button block>Email Invoice</Button>
                </Card>
              </Col>
            </Row>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item key="consultation-bill">Consultation Bill</Menu.Item>
            <Menu.Item key="spectacle-bill">Spectacle Bill</Menu.Item>
            <Menu.Item key="contact-lens-bill">Contact Lens Bill</Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default BillingDetails;
