/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import {
  Form,
  Button,
  Space,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Radio,
  message,
  Tooltip,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";

import { NavLink } from "react-router-dom";
import { StyledDrawer } from "../styles";
import { useAppointmentContext } from "../context";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import { CopyOutlined, FileDoneOutlined } from "@ant-design/icons";
import Title from "antd/es/skeleton/Title";

const { Option } = Select;

const dummyPatients = [
  {
    id: "1",
    firstName: "John",
    lastName: "Doe",
    mobileNumber: "1234567890",
    email: "john@example.com",
    dateOfBirth: "1990-01-01",
    gender: "male",
  },
  {
    id: "2",
    firstName: "Jane",
    lastName: "Smith",
    mobileNumber: "0987654321",
    email: "jane@example.com",
    dateOfBirth: "1985-05-12",
    gender: "female",
  },
];

const getPatientByIdentifier = (identifier: any) => {
  // Simulate fetching a patient by ID or name
  return dummyPatients.find((patient) => patient.id === identifier);
};

const AppointmentForm = () => {
  const [form] = Form.useForm();
  const { isEditMode, setIsEditMode, open, setOpen } = useAppointmentContext();
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchIdentifier, setSearchIdentifier] = useState("");
  const [patientNotFound, setPatientNotFound] = useState(false);
  const [copiedData, setCopiedData] = useState<any>(null);

  const onFinish = (values: any) => {
    console.log("Form values:", values);
    // Handle form submission here (e.g., API call)
  };
  const handleSearchPatient = () => {
    const patientData = getPatientByIdentifier(searchIdentifier);
    if (patientData) {
      setPatientNotFound(false);
      form.setFieldsValue({
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        mobileNumber: patientData.mobileNumber,
        email: patientData.email,
        dateOfBirth: patientData.dateOfBirth ? dayjs(patientData.dateOfBirth) : null,
        gender: patientData.gender,
      });
      message.success("Patient details found and filled in.");
    } else {
      setPatientNotFound(true);
      form.resetFields(["firstName", "lastName", "mobileNumber", "email", "dateOfBirth", "gender"]);
      message.error("No such user found.");
    }
  };

  const handleCopy = () => {
    const values = form.getFieldsValue();
    setCopiedData(values);
    message.success("Form data copied successfully!");
  };

  const handlePaste = () => {
    if (copiedData) {
      form.setFieldsValue({
        ...copiedData,
        dateOfBirth: copiedData.dateOfBirth ? dayjs(copiedData.dateOfBirth) : null,
      });
      message.success("Form data pasted successfully!");
    } else {
      message.warning("No data to paste. Please copy data first.");
    }
  };

  return (
    <StyledDrawer
      closable
      destroyOnClose
      title={
        isEditMode ? (
          <Row justify={"space-between"}>
            <span style={{ fontSize: "16px", marginTop: "3px" }}>Edit Appointment</span>
            <Space>
              <Tooltip title="Copy Data">
                <Button icon={<CopyOutlined />} onClick={handleCopy} style={{ marginRight: 8 }} />
              </Tooltip>
              <Tooltip title="Paste Data">
                <Button icon={<FileDoneOutlined />} onClick={handlePaste} />
              </Tooltip>
            </Space>
          </Row>
        ) : (
          <Row justify={"space-between"}>
            <span style={{ fontSize: "16px", marginTop: "3px" }}>Add Appointment</span>
            <Space>
              <Tooltip title="Copy Data">
                <Button icon={<CopyOutlined />} onClick={handleCopy} style={{ marginRight: 8 }} />
              </Tooltip>
              <Tooltip title="Paste Data">
                <Button icon={<FileDoneOutlined />} onClick={handlePaste} />
              </Tooltip>
            </Space>
          </Row>
        )
      }
      placement="right"
      open={open}
      onClose={() => {
        setOpen(false);
        setIsEditMode(false);
      }}
      width={500}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          enableMessage: true, // Set initial values as needed
        }}
      >
        <Row gutter={[6, 6]}>
          <Col span={12}>
            <Form.Item label="Booking Type" name="bookingType" rules={[{ required: true }]}>
              <Radio.Group optionType="button" buttonStyle="solid" defaultValue={"walkIn"}>
                <Radio value="walkIn">Walk-In</Radio>
                <Radio value="appointment">Appointment</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {/* Patient Type */}
          <Col span={12}>
            <Form.Item label="Patient Type" name="patientType" rules={[{ required: true }]}>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                defaultValue={"new"}
                onChange={(e) => setShowSearchInput(e.target.value === "existing")}
              >
                <Radio value="existing">Existing </Radio>
                <Radio value="new">New Patient</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {showSearchInput && (
            <Col span={24}>
              <Form.Item label="Enter Unique ID">
                <Input.Search
                  placeholder="Enter Patient ID"
                  enterButton="Search"
                  value={searchIdentifier}
                  onChange={(e) => setSearchIdentifier(e.target.value)}
                  onSearch={handleSearchPatient}
                />
              </Form.Item>
              {patientNotFound && <p style={{ color: "red" }}>No such user found</p>}
            </Col>
          )}

          <Col span={12}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your first name" />
            </Form.Item>
          </Col>

          {/* Last Name */}
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your last name" />
            </Form.Item>
          </Col>

          {/* Mobile Number */}
          <Col span={12}>
            <Form.Item
              name="mobileNumber"
              label="Mobile Number"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your mobile number" />
            </Form.Item>
          </Col>

          {/* Date of Birth */}
          <Col span={12}>
            <Form.Item name="dateOfBirth" label="Date of Birth">
              <DatePicker style={{ width: "100%" }} placeholder="Select date of birth" />
            </Form.Item>
          </Col>

          {/* Email */}
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ type: "email", message: "Invalid email type" }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </Col>

          {/* Gender */}
          <Col span={12}>
            <Form.Item name="gender" label="Gender">
              <Select placeholder="Select gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Appointment Type */}
          <Col span={24}>
            <Form.Item name="appointmentType" label="Appointment Type">
              <Select placeholder="Select type">
                <Option value="walk">Walk In</Option>
                <Option value="appointment">Appointment</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Date and Time */}
          <Col span={12}>
            <Form.Item name="dateAndTime" label="Date and Time">
              <DatePicker showTime style={{ width: "100%" }} placeholder="Select date and time" />
            </Form.Item>
          </Col>

          {/* Room */}
          <Col span={12}>
            <Form.Item name="room" label="Room">
              <Select placeholder="Select room">
                <Option value="room1">Room 1</Option>
                <Option value="room2">Room 2</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Status */}
          <Col span={12}>
            <Form.Item name="status" label="Status">
              <Select placeholder="Select status">
                <Option value="scheduled">Scheduled</Option>
                <Option value="completed">Completed</Option>
                <Option value="cancelled">Cancelled</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Scheduled Practitioner */}
          <Col span={12}>
            <Form.Item name="scheduledPractitioner" label="Scheduled Practitioner">
              <Select placeholder="Select practitioner">
                <Option value="dr_smith">Dr. Smith</Option>
                <Option value="dr_jones">Dr. Jones</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="bookingNotes" label="Booking Notes">
              <TextArea rows={3} placeholder="Enter booking notes" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="enableMessage" valuePropName="checked">
              <Checkbox defaultChecked>
                Enable message to the patient for this appointment booking.
              </Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="isGuardian" valuePropName="checked">
              <Checkbox>Are you a guardian booking on behalf of the patient?</Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            {" "}
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isGuardian !== currentValues.isGuardian
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("isGuardian") ? (
                  <Row gutter={[18, 18]}>
                    <Col span={12}>
                      <Form.Item
                        name="guardianFullName"
                        label="Guardian Full Name"
                        rules={[{ required: true, message: "Guardian's full name is required" }]}
                      >
                        <Input placeholder="Enter guardian's full name" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="guardianMobile"
                        label="Guardian Mobile Number"
                        rules={[
                          { required: true, message: "Guardian's mobile number is required" },
                        ]}
                      >
                        <Input placeholder="Enter guardian's mobile number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="relationWithPatient"
                        label="Relation with Patient"
                        rules={[{ required: true, message: "Relation with patient is required" }]}
                      >
                        <Input placeholder="Enter relation with patient" />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null
              }
            </Form.Item>
          </Col>

          {/* Enable Message Checkbox */}
        </Row>

        <Row justify={"end"}>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="default" onClick={() => form.resetFields()}>
              Reset
            </Button>
          </Space>
        </Row>
      </Form>
    </StyledDrawer>
  );
};

export default AppointmentForm;
