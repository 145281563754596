import { ROUTES } from "constants/routeConstants";

const getAllBaseRoutes = (routesObj: Record<string, any>): string[] => {
  let baseRoutes: string[] = [];

  Object.values(routesObj).forEach((route) => {
    if (typeof route === "string") {
      const mainSegment = route.split("/").slice(0, 2).join("/");
      baseRoutes.push(mainSegment);
    } else if (typeof route === "object") {
      if (route.BASE) {
        baseRoutes.push(route.BASE);
      } else {
        Object.values(route).forEach((nestedRoute: any) => {
          if (typeof nestedRoute === "string") {
            const mainSegment = nestedRoute.split("/").slice(0, 2).join("/");
            baseRoutes.push(mainSegment);
          } else if (typeof nestedRoute === "object" && nestedRoute?.BASE) {
            baseRoutes.push(nestedRoute?.BASE);
          }
        });
      }
    }
  });

  return Array.from(new Set(baseRoutes));
};

export const getParentRoute = (pathname: string): string | undefined => {
  const baseRoutes = getAllBaseRoutes(ROUTES);
  const parentRoute = baseRoutes
    .filter((route) => pathname.startsWith(route))
    .sort((a, b) => b.length - a.length)[0];

  return parentRoute || ROUTES.NOTFOUND;
};
