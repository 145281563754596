/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import {
  Tabs,
  Menu,
  Dropdown,
  Card,
  Row,
  Typography,
  Avatar,
  List,
  Button,
  Tag,
  Modal,
  Space,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import PageTemplate from "components/PageTemplate";
import ProfileHeader from "./ProfileHeader";
import DefaultLayout from "./DefaultLayout";
import InitialContactLens from "./InitialContactLens";
import ImagerySection from "./ImageryTab";
import DocumentsTab from "./DocumentsTab";
import CorrespondenceTab from "./CorrespondenceTab";
import RecallSettingTab from "./RecallSettingTab";
import BillingDetails from "./BillingDetails";
import { CalendarOutlined, UserOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ContactLensPage from "./ContactLensPage";
import SpectacleDetailsPage from "./SpectacleDetailsPage";

const { TabPane } = Tabs;

const listData = [
  {
    title: "Ocular History",
    tags: [
      "Myopia since childhood",
      "Underwent cataract surgery in 2015",
      "Diagnosed with astigmatism in 2020",
      "History of retinal detachment",
    ],
  },
  {
    title: "Family History",
    tags: [
      "Father diagnosed with glaucoma at age 60",
      "Mother has type 2 diabetes",
      "Brother has hypertension",
    ],
  },
  {
    title: "Allergy",
    tags: ["Allergic to penicillin", "Reacts to pollen in spring", "Dust mites cause sneezing"],
  },
  {
    title: "Medications",
    tags: [
      "Latanoprost for glaucoma",
      "Metformin for diabetes",
      "Daily aspirin",
      "Simvastatin for cholesterol",
    ],
  },
  {
    title: "Lifestyle",
    tags: [
      "Non-smoker for 10 years",
      "Engages in moderate exercise 3 times a week",
      "Follows a vegetarian diet",
      "Occasional social drinking",
    ],
  },
  {
    title: "Others",
    tags: [
      "Experiences frequent headaches",
      "Sensitive to bright lights (photophobia)",
      "Chronic fatigue since 2021",
    ],
  },
];

const recommendations = [
  {
    date: "Sep 1, 2024",
    content: "Patient seems to have Dry Eye Syndrome. Recommended the use of Artificial Tears.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Aug 28, 2024",
    content:
      "Patient has a history of hypertension. Recommended monitoring blood pressure regularly.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Aug 15, 2024",
    content: "Patient shows signs of early cataracts. Suggested routine eye exams.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Jul 30, 2024",
    content: "Patient reports frequent headaches. Recommended a comprehensive eye exam.",
    by: "Dr. Daniel Tu",
  },
  {
    date: "Jul 10, 2024",
    content:
      "Patient is a smoker. Advised to quit smoking to reduce the risk of macular degeneration.",
    by: "Dr. Daniel Tu",
  },
];

const ConsultationDetailsPage: React.FC = () => {
  const [selectedLayout, setSelectedLayout] = useState<string>("default");
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTitle, setCurrentTitle] = useState("");
  const toggleModal = (isVisible: boolean, title?: string) => {
    if (title) setCurrentTitle(title);
    setModalVisible(isVisible);
  };

  const handleMenuClick = (e: any) => {
    setSelectedLayout(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="default">Default Layout</Menu.Item>
      <Menu.Item key="initial">Initial Contact Lens</Menu.Item>
    </Menu>
  );

  const renderAvatar = (size: number, initials: string = "DT") => (
    <Avatar
      size={size}
      style={{
        backgroundColor: "#C8EFF9",
        color: "#0C5C6E",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      {initials}
    </Avatar>
  );

  const renderTagList = (tags: string[]) =>
    tags.map((tag) => (
      <Tag
        closable
        key={tag}
        style={{
          backgroundColor: "#F3F4F6",
          borderColor: "#D1D5DB",
          color: "#6B7280",
          marginBottom: "8px",
          marginRight: "8px",
        }}
      >
        {tag}
      </Tag>
    ));

  const renderRecommendationList = recommendations.map((rec, index) => (
    <Card key={index} style={{ marginBottom: 20, border: "1px solid #D1D5DB" }}>
      <Row align="top" style={{ marginBottom: 8 }}>
        {renderAvatar(40)}
        <div style={{ flex: 1, marginLeft: 12 }}>
          {rec.content}
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              gap: "12px",
              alignItems: "center",
            }}
          >
            <span>
              <CalendarOutlined style={{ marginRight: 4 }} />
              <Typography.Text strong>Recommended Date:</Typography.Text> {rec.date}
            </span>

            <span
              style={{
                border: "1px solid #A3E4F5",
                backgroundColor: "#E3F7FC",
                color: "#0C5C6E",
                padding: "2px 6px",
                borderRadius: "6px",
              }}
            >
              <UserOutlined style={{ marginRight: 4 }} />
              <span>Recommended By: {rec.by}</span>
            </span>
          </div>
        </div>
      </Row>
    </Card>
  ));

  const tabItems = [
    {
      label: (
        <Dropdown overlay={menu} trigger={["click"]} css={dropdownStyle}>
          <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
            <span>{selectedLayout === "default" ? "Default Layout" : "Initial Contact Lens"}</span>
            <DownOutlined style={{ marginLeft: 8 }} />
          </div>
        </Dropdown>
      ),
      key: "layout",
      content: selectedLayout === "default" ? <DefaultLayout /> : <InitialContactLens />,
    },
    // {
    //   label: "Patient Summary",
    //   key: "patientsummary",
    // },
    {
      label: "Imagery",
      key: "imagery",
      content: <ImagerySection />,
    },
    {
      label: "Documents",
      key: "documents",
      content: <DocumentsTab />,
    },
    {
      label: "Correspondence",
      key: "correspondence",
      content: <CorrespondenceTab />,
    },
    {
      label: "Recall Settings",
      key: "recall",
      content: <RecallSettingTab />,
    },

    {
      label: "Recommendations",
      key: "recommendations",
      content: (
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          {renderRecommendationList}
        </Card>
      ),
    },
    {
      label: "Notes",
      key: "notes",
      content: (
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={listData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    style={{
                      color: "#007BFF",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      backgroundColor: "#E3F7FC",
                      borderColor: "#007BFF",
                      borderWidth: "1px",
                    }}
                    onClick={() => toggleModal(true, item.title)}
                  >
                    Add
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={<span style={{ fontWeight: "bold" }}>{item.title}</span>}
                  description={renderTagList(item.tags)}
                />
              </List.Item>
            )}
          />
        </Card>
      ),
    },
    {
      label: "Spectacle Details",
      key: "consultationhistory",
      content: <SpectacleDetailsPage />,
    },

    {
      label: "Contact Lens Details",
      key: "contactlens",
      content: <ContactLensPage />,
    },
    {
      label: "Final Bill",
      key: "consultationbilling",
      content: <BillingDetails />,
    },
  ];

  return (
    <PageTemplate currentNode="Consult">
      <ProfileHeader />
      <Tabs
        defaultActiveKey="layout"
        tabBarStyle={{ background: "#f5f5f5", borderBottom: "1px solid #e0e0e0" }}
      >
        {tabItems.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>

      <Modal
        title={currentTitle}
        visible={modalVisible}
        onCancel={() => toggleModal(false)}
        footer={
          <Space>
            <Button onClick={() => toggleModal(false)}>Cancel</Button>
            <Button type="primary">Submit</Button>
          </Space>
        }
      >
        <TextArea rows={2} placeholder={`Add ${currentTitle}...`} />
      </Modal>
    </PageTemplate>
  );
};

const dropdownStyle = css`
  .ant-dropdown {
    display: inline-block;
    cursor: pointer;
  }
`;

export default ConsultationDetailsPage;
