import React, { useState } from "react";
import {
  Card,
  Divider,
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Table,
  Radio,
  Descriptions,
} from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const SupplierInvoiceCreditSummaryReport: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  // Handle form submission
  const handleFinish = (values: any) => {
    console.log("Report values: ", values);

    // Set the formatted date range
    setDateRange([
      dayjs(values?.startDate)?.format("DD-MM-YYYY"),
      dayjs(values?.endDate)?.format("DD-MM-YYYY"),
    ]);

    // Dummy data for Supplier Invoice Credit Summary Report
    setReportData([
      {
        key: 1,
        supplierCode: "SUP001",
        supplier: "Supplier A",
        totalAmount: "$1000",
        gst: "$100",
        taxableAmount: "$800",
        nonTaxableAmount: "$200",
      },
      {
        key: 2,
        supplierCode: "SUP002",
        supplier: "Supplier B",
        totalAmount: "$1500",
        gst: "$150",
        taxableAmount: "$1200",
        nonTaxableAmount: "$300",
      },
      {
        key: 3,
        supplierCode: "SUP003",
        supplier: "Supplier C",
        totalAmount: "$700",
        gst: "$70",
        taxableAmount: "$500",
        nonTaxableAmount: "$200",
      },
      // Add more rows as needed
    ]);
  };

  // Define the table columns
  const columns = [
    { title: "Supplier Code", dataIndex: "supplierCode", key: "supplierCode" },
    { title: "Supplier", dataIndex: "supplier", key: "supplier" },
    { title: "Total Amount (Inclusive Tax)", dataIndex: "totalAmount", key: "totalAmount" },
    { title: "GST", dataIndex: "gst", key: "gst" },
    { title: "Taxable Amount (Inclusive Tax)", dataIndex: "taxableAmount", key: "taxableAmount" },
    { title: "Non-Taxable Amount", dataIndex: "nonTaxableAmount", key: "nonTaxableAmount" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Supplier Invoice Credit Summary Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Supplier Code"
                name="supplierCode"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Supplier Code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Supplier Invoice Credit Summary Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
          <Card
            style={{
              marginTop: "24px",
              border: "1px dashed #d9d9d9",
              borderRadius: "4px",
              padding: "16px",
            }}
          >
            <Descriptions column={4} title="" layout="vertical">
              <Descriptions.Item label="Total Amount">
                $
                {reportData
                  .reduce((sum, record) => sum + parseFloat(record.totalAmount.slice(1)), 0)
                  .toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="GST">
                $
                {reportData
                  .reduce((sum, record) => sum + parseFloat(record.gst.slice(1)), 0)
                  .toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="Taxable Amount">
                $
                {reportData
                  .reduce((sum, record) => sum + parseFloat(record.taxableAmount.slice(1)), 0)
                  .toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="Non-Taxable Amount">
                $
                {reportData
                  .reduce((sum, record) => sum + parseFloat(record.nonTaxableAmount.slice(1)), 0)
                  .toFixed(2)}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Card>
      )}
    </>
  );
};

export default SupplierInvoiceCreditSummaryReport;
