import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, DatePicker, Button, Table } from "antd";
import dayjs from "dayjs";

const RecallStatistics: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setDateRange([
      dayjs(values.startDate).format("DD-MM-YYYY"),
      dayjs(values.endDate).format("DD-MM-YYYY"),
    ]);

    // Dummy data for demonstration purposes
    setReportData([
      {
        key: 1,
        metric: "Number of Patient Recall",
        Jan: 50,
        Feb: 60,
        Mar: 70,
        Apr: 80,
        Total: 260,
      },
      {
        key: 2,
        metric: "Subsequent Recall",
        Jan: 20,
        Feb: 25,
        Mar: 30,
        Apr: 35,
        Total: 110,
      },
      {
        key: 3,
        metric: "Success",
        Jan: 40,
        Feb: 50,
        Mar: 60,
        Apr: 70,
        Total: 220,
      },
      {
        key: 4,
        metric: "Overall Success %",
        Jan: "80%",
        Feb: "83%",
        Mar: "86%",
        Apr: "88%",
        Total: "84%",
      },
      {
        key: 5,
        metric: "Total Sale",
        Jan: 1000,
        Feb: 1200,
        Mar: 1500,
        Apr: 1700,
        Total: 5400,
      },
      {
        key: 6,
        metric: "Success % <= 30 Days",
        Jan: "70%",
        Feb: "75%",
        Mar: "78%",
        Apr: "80%",
        Total: "76%",
      },
      {
        key: 7,
        metric: "Success % 31-60 Days",
        Jan: "65%",
        Feb: "68%",
        Mar: "70%",
        Apr: "72%",
        Total: "69%",
      },
      {
        key: 8,
        metric: "Success % 61-90 Days",
        Jan: "60%",
        Feb: "62%",
        Mar: "64%",
        Apr: "66%",
        Total: "63%",
      },
      {
        key: 9,
        metric: "Success % 91-180 Days",
        Jan: "55%",
        Feb: "58%",
        Mar: "60%",
        Apr: "62%",
        Total: "59%",
      },
      {
        key: 10,
        metric: "Success % 6-12 Months",
        Jan: "50%",
        Feb: "52%",
        Mar: "54%",
        Apr: "56%",
        Total: "53%",
      },
      {
        key: 11,
        metric: "Success % 12-24 Months",
        Jan: "45%",
        Feb: "48%",
        Mar: "50%",
        Apr: "52%",
        Total: "49%",
      },
      {
        key: 12,
        metric: "Success % After 24 Months",
        Jan: "40%",
        Feb: "42%",
        Mar: "44%",
        Apr: "46%",
        Total: "43%",
      },
      {
        key: 13,
        metric: "Back Within 30 Days",
        Jan: 30,
        Feb: 35,
        Mar: 40,
        Apr: 45,
        Total: 150,
      },
      {
        key: 14,
        metric: "Back Between 31-60 Days",
        Jan: 25,
        Feb: 30,
        Mar: 35,
        Apr: 40,
        Total: 130,
      },
      {
        key: 15,
        metric: "Back Between 61-90 Days",
        Jan: 20,
        Feb: 25,
        Mar: 30,
        Apr: 35,
        Total: 110,
      },
      {
        key: 16,
        metric: "Back Between 91-180 Days",
        Jan: 15,
        Feb: 20,
        Mar: 25,
        Apr: 30,
        Total: 90,
      },
      {
        key: 17,
        metric: "Back Between 6-12 Months",
        Jan: 10,
        Feb: 15,
        Mar: 20,
        Apr: 25,
        Total: 70,
      },
      {
        key: 18,
        metric: "Back Between 12-24 Months",
        Jan: 5,
        Feb: 10,
        Mar: 15,
        Apr: 20,
        Total: 50,
      },
      {
        key: 19,
        metric: "Back After 24 Months",
        Jan: 3,
        Feb: 5,
        Mar: 7,
        Apr: 10,
        Total: 25,
      },
    ]);
  };

  const columns = [
    { title: "", dataIndex: "metric", key: "metric" },
    { title: "Jan", dataIndex: "Jan", key: "Jan" },
    { title: "Feb", dataIndex: "Feb", key: "Feb" },
    { title: "Mar", dataIndex: "Mar", key: "Mar" },
    { title: "Apr", dataIndex: "Apr", key: "Apr" },
    { title: "Total", dataIndex: "Total", key: "Total" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Recall Statistics
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Recall Statistics {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default RecallStatistics;
