import { Navigate, useRoutes, RouteObject } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import { RouteComponents } from "./routeComponents";
import { ROUTES } from "constants/routeConstants";
import { routeConfigs } from "./routeConfig";

const createRoute = (
  path: string,
  element: React.ReactElement,
  isPrivate = false
): RouteObject => ({
  path,
  element: isPrivate ? <PrivateRoute element={element} /> : element,
});

const publicRoutes: RouteObject[] = [
  ...routeConfigs.authRoutes,
  //   createRoute(ROUTES.NOTFOUND, <RouteComponents.NotFound />),
];

const privateRoutes: RouteObject[] = [
  createRoute(ROUTES.AUTH_ROUTES.CHANGE_PASSWORD, <RouteComponents.ChangePasswordPage />),
  ...routeConfigs.dashboardRoutes,
  ...routeConfigs.appointmentRoutes,
  ...routeConfigs.patientRecordsRoutes,
  ...routeConfigs.subscriptionRoutes,
  ...routeConfigs.consultationRoutes,
  ...routeConfigs.supportRoutes,
  ...routeConfigs.userRoutes,
  ...routeConfigs.financialRoutes,
  ...routeConfigs.inventoryRoutes,
  ...routeConfigs.masterSetupRoutes,
  ...routeConfigs.statisticsRoutes,
  createRoute(ROUTES.NOTFOUND, <RouteComponents.NotFound />),
];

const mainRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: "/",
    element: <PrivateRoute element={<RouteComponents.LayoutPage />} />,
    children: privateRoutes,
  },

  ...publicRoutes,
];

export function MainRoutes() {
  return useRoutes(mainRoutes);
}
