import React, { useState } from "react";
import { Row, Space, Button, Avatar, Typography, Dropdown, Menu, Badge } from "antd";
import { CalendarOutlined, DownOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { StyledTitle, HeaderContainer } from "../styles";

const ProfileHeader: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("Eye Test");
  const navigate = useNavigate();

  const renderAvatar = (size: number, initials: string = "DT") => (
    <Avatar
      size={size}
      style={{
        backgroundColor: "#C8EFF9",
        color: "#0C5C6E",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      {initials}
    </Avatar>
  );

  const renderTitle = (title: string, date: string) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <StyledTitle level={5} style={{ marginBottom: 0 }}>
        {title}
      </StyledTitle>
      <Space>
        <Typography.Text
          type="secondary"
          style={{ display: "flex", alignItems: "center", marginLeft: 8 }}
        >
          <CalendarOutlined style={{ marginRight: 4 }} />
          {date}
        </Typography.Text>

        <Dropdown
          overlay={
            <Menu onClick={({ key }) => setSelectedOption(key)}>
              <Menu.Item key="Eye Test">Eye Test</Menu.Item>
              <Menu.Item key="Children Vision Testing">Children Vision Testing</Menu.Item>
              <Menu.Item key="Contact Lens Consultation">Contact Lens Consultation</Menu.Item>
              <Menu.Item key="Contact Lens Follow-up">Contact Lens Follow-up</Menu.Item>
              <Menu.Item key="Emergency Consult">Emergency Consult</Menu.Item>
              <Menu.Item key="Eyelash Removal">Eyelash Removal</Menu.Item>
              <Menu.Item key="Glaucoma Test">Glaucoma Test</Menu.Item>
              <Menu.Item key="OCT Test">OCT Test</Menu.Item>
              <Menu.Item key="Telehealth Video Consultation">
                Telehealth Video Consultation
              </Menu.Item>
              <Menu.Item key="Visual Field Test">Visual Field Test</Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Typography.Text
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: 8,
            }}
          >
            <Badge status="error" style={{ marginRight: "8px" }} />
            {selectedOption} <DownOutlined style={{ marginLeft: 4 }} />
          </Typography.Text>
        </Dropdown>
      </Space>
    </div>
  );

  return (
    <div>
      <HeaderContainer>
        <Row justify="space-between" align="middle">
          <Space align="center">
            {renderAvatar(45)}
            {renderTitle("Mr. Daniel TU", "Sep 2, 2024")}
          </Space>

          {/* Back Button on the Right */}
          <Button
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate("/consultation")}
          >
            Back
          </Button>
        </Row>
      </HeaderContainer>
    </div>
  );
};

export default ProfileHeader;
