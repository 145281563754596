import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Input, DatePicker, Table, Radio, Button } from "antd";
import dayjs from "dayjs";

const BillingItemReportByProvider: React.FC = () => {
  const [reportData, setReportData] = useState<any>({});
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  // Handle form submission
  const handleFinish = (values: any) => {
    console.log("Report values:", values);

    // Set the formatted date range
    setDateRange([
      dayjs(values?.startDate)?.format("DD-MM-YYYY"),
      dayjs(values?.endDate)?.format("DD-MM-YYYY"),
    ]);

    // Dummy data for Billing Item Report By Provider
    setReportData({
      "Report Group": [
        {
          key: 1,
          opt: "Provider A",
          by: "User A",
          itemNumber: "ITEM001",
          description: "Item 1 Description",
          quantity: 5,
          itemAmount: "$500",
          gst: "$50",
        },
        {
          key: 2,
          opt: "Provider B",
          by: "User B",
          itemNumber: "ITEM002",
          description: "Item 2 Description",
          quantity: 3,
          itemAmount: "$300",
          gst: "$30",
        },
      ],
      "Report Group: Contact Lens": [
        {
          key: 3,
          opt: "Provider C",
          by: "User C",
          itemNumber: "ITEM003",
          description: "Item 3 Description",
          quantity: 7,
          itemAmount: "$700",
          gst: "$70",
        },
      ],
      "Report Group: Consultation": [
        {
          key: 4,
          opt: "Provider D",
          by: "User D",
          itemNumber: "ITEM004",
          description: "Item 4 Description",
          quantity: 2,
          itemAmount: "$200",
          gst: "$20",
        },
      ],
      "Report Group: Frame": [
        {
          key: 5,
          opt: "Provider E",
          by: "User E",
          itemNumber: "ITEM005",
          description: "Item 5 Description",
          quantity: 4,
          itemAmount: "$400",
          gst: "$40",
        },
      ],
    });
  };

  // Define the table columns
  const columns = [
    { title: "Opt", dataIndex: "opt", key: "opt" },
    { title: "By", dataIndex: "by", key: "by" },
    { title: "Item Number", dataIndex: "itemNumber", key: "itemNumber" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Item Amount", dataIndex: "itemAmount", key: "itemAmount" },
    { title: "GST", dataIndex: "gst", key: "gst" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Billing Item Report By Provider
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Provider"
                name="provider"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Provider" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Report Destination"
                name="reportDestination"
                rules={[{ required: true, message: "Required" }]}
              >
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {Object.keys(reportData).map((group) => (
        <Card
          key={group}
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            {group} {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData[group]} pagination={false} />
        </Card>
      ))}
    </>
  );
};

export default BillingItemReportByProvider;
