import React from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Divider,
  Select,
  InputNumber,
  Upload,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const ExpensePayment: React.FC = () => {
  const handleFinish = (values: any) => {
    console.log("Expense Payment values:", values);
    // Handle form submission logic here
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Expense Payment
          </Divider>

          <Typography.Title level={5} style={{ margin: 0 }}>
            Payement Ref
          </Typography.Title>
          <Typography.Text>Q000236</Typography.Text>

          <Row gutter={24} style={{ marginTop: "28px" }}>
            <Col span={8}>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Supplier Code"
                name="supplierCode"
                rules={[{ required: true, message: "Please enter the supplier code" }]}
              >
                <Input placeholder="Enter supplier code" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Supplier Ref" name="supplierRef">
                <Input placeholder="Enter supplier reference" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Cheque No. / Payment Ref" name="chequeRef">
                <Input placeholder="Enter cheque number or payment reference" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Expense Code" name="expenseCode">
                <Input placeholder="Enter expense code" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Select Invoices" name="selectInvoices">
                <Upload>
                  <Button icon={<UploadOutlined />}>Upload Invoice</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Description" name="description">
                <Input.TextArea rows={4} placeholder="Enter description" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="Total Amt $" name="totalAmt">
                <InputNumber style={{ width: "100%" }} min={0} placeholder="Enter total amount" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="GST Amt $" name="gstAmt">
                <InputNumber style={{ width: "100%" }} min={0} placeholder="Enter GST amount" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Tax %" name="taxPercent">
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  max={100}
                  placeholder="Enter tax percentage"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Taxable Amt (Inc. Tax) $" name="taxableAmt">
                <InputNumber style={{ width: "100%" }} min={0} placeholder="Enter taxable amount" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Non-Taxable Amt $" name="nonTaxableAmt">
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  placeholder="Enter non-taxable amount"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="End Date" name="endDate">
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <div>
              <Button htmlType="reset">Reset</Button>
              <Button type="primary" htmlType="submit" style={{ marginLeft: "12px" }}>
                Create Invoice for this Payment
              </Button>
            </div>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default ExpensePayment;
