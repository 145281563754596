import React, { useState } from "react";
import { Card, Row, Col, Menu, Divider, Button, Table, Form, DatePicker } from "antd";

const { RangePicker } = DatePicker;

const ReverseNegativeBillingItemReport: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("negative-values");
  const [reportData, setReportData] = useState<any[]>([]);

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleFinish = (values: any) => {
    console.log("Form Values:", values);

    // Dummy data for table
    setReportData([
      {
        key: 1,
        patRef: "JohnDoe123",
        billNumber: "B00123",
        billDate: "2024-12-01",
        itemNumber: "I001",
        description: "Consultation Service",
        amount: "-100.00",
        gst: "-10.00",
        code: "NEG001",
      },
    ]);
  };

  const columns = [
    { title: "Pat Ref", dataIndex: "patRef", key: "patRef" },
    { title: "Bill Number", dataIndex: "billNumber", key: "billNumber" },
    { title: "Bill Date", dataIndex: "billDate", key: "billDate" },
    { title: "Item Number", dataIndex: "itemNumber", key: "itemNumber" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "GST", dataIndex: "gst", key: "gst" },
    { title: "Code", dataIndex: "code", key: "code" },
  ];

  const renderContent = () => (
    <Card style={{ minHeight: "calc(100vh - 138px)", overflowY: "auto" }}>
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        {selectedKey === "negative-values" ? "Negative Values" : "Reversals"}
      </Divider>
      <Form layout="vertical" onFinish={handleFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Start Date is required" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "End Date is required" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button type="primary" htmlType="submit" style={{ marginTop: "34px" }}>
            Generate Report
          </Button>
        </Row>
      </Form>
      {reportData.length > 0 && (
        <>
          <Divider orientation="left" orientationMargin="0" style={{ marginTop: "24px" }}>
            Report Data
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </>
      )}
    </Card>
  );

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item key="negative-values">Negative Values</Menu.Item>
            <Menu.Item key="reversals">Reversals</Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default ReverseNegativeBillingItemReport;
