import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, DatePicker, Button } from "antd";
import { Column } from "@ant-design/charts";
import dayjs, { Dayjs } from "dayjs";

const BillingSummaryByGroup = () => {
  const [chartData, setChartData] = useState<any[]>([]);
  const [years, setYears] = useState<{ from: Dayjs } | null>(null);

  const handleFinish = (values: { year: dayjs.Dayjs }) => {
    const year = dayjs(values.year);

    setYears({ from: year });

    const data = [
      { month: "Jan", category: "Consult", sales: 750 },
      { month: "Jan", category: "Frame", sales: 500 },
      { month: "Jan", category: "Lens", sales: 350 },
      { month: "Jan", category: "Contact Lens", sales: 200 },
      { month: "Jan", category: "Sunglasses", sales: 600 },

      { month: "Feb", category: "Consult", sales: 700 },
      { month: "Feb", category: "Frame", sales: 450 },
      { month: "Feb", category: "Lens", sales: 300 },
      { month: "Feb", category: "Contact Lens", sales: 180 },
      { month: "Feb", category: "Sunglasses", sales: 550 },

      // Add data for all months
      { month: "Mar", category: "Consult", sales: 730 },
      { month: "Mar", category: "Frame", sales: 470 },
      { month: "Mar", category: "Lens", sales: 320 },
      { month: "Mar", category: "Contact Lens", sales: 210 },
      { month: "Mar", category: "Sunglasses", sales: 580 },

      // Repeat for other months...
      { month: "Dec", category: "Consult", sales: 760 },
      { month: "Dec", category: "Frame", sales: 480 },
      { month: "Dec", category: "Lens", sales: 330 },
      { month: "Dec", category: "Contact Lens", sales: 220 },
      { month: "Dec", category: "Sunglasses", sales: 610 },
    ];

    // Update chartData
    setChartData(data);
  };
  const config = {
    data: chartData, // Use chartData here
    isGroup: true,
    xField: "month",
    yField: "sales",
    seriesField: "category", // Determines grouping
    colorField: "category", // Assigns a unique color to each bar
    color: ["#6366F1", "#38BDF8", "#34D399", "#FACC15", "#F43F5E"], // Colors for each category
    yAxis: {
      label: {
        formatter: (v: number) => `$${v}`, // Format y-axis values
      },
    },
    legend: {
      position: "top",
    },
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Year "
                name="year"
                rules={[{ required: true, message: "Please select the starting year" }]}
              >
                <DatePicker picker="year" placeholder="Select Year" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button type="primary" htmlType="submit" style={{ marginTop: "34px" }}>
              Generate Report
            </Button>
          </Row>
        </Form>
      </Card>

      {chartData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Graph Report
          </Divider>
          <Column {...config} />
        </Card>
      )}
    </>
  );
};

export default BillingSummaryByGroup;
