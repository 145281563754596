import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Button, DatePicker, Table } from "antd";
import dayjs from "dayjs";

const SpectaclePrescriptionReport: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setDateRange([
      dayjs(values.startDate).format("DD-MM-YYYY"),
      dayjs(values.endDate).format("DD-MM-YYYY"),
    ]);

    // Dummy data for demonstration purposes
    setReportData([
      {
        date: "12-10-2024",
        optometrist: "Dr. Smith",
        prescriptions: [
          { key: 1, patient: "John Doe", rx: "+1.50", rxNote: "Near vision correction" },
          { key: 2, patient: "Jane Doe", rx: "-2.00", rxNote: "Distance correction" },
        ],
      },
      {
        date: "15-10-2024",
        optometrist: "Dr. Johnson",
        prescriptions: [
          { key: 3, patient: "Sam Smith", rx: "+0.75", rxNote: "Astigmatism correction" },
        ],
      },
      {
        date: "18-10-2024",
        optometrist: "Dr. Williams",
        prescriptions: [
          { key: 4, patient: "Alex Brown", rx: "-1.25", rxNote: "Mild myopia correction" },
        ],
      },
    ]);
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Spectacle Prescription Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <>
          {reportData.map((entry, index) => (
            <Card
              key={index}
              style={{
                marginTop: "24px",
                boxShadow: "0px 8px 40px -10px #00000014",
                borderRadius: "4px",
              }}
            >
              <Divider orientation="left" orientationMargin="0">
                Spectacle Prescription Report for {entry.date}
              </Divider>
              <p>
                <strong>Optometrist:</strong> {entry.optometrist}
              </p>
              <Table
                columns={[
                  { title: "Patient", dataIndex: "patient", key: "patient" },
                  { title: "RX", dataIndex: "rx", key: "rx" },
                  { title: "RX Note", dataIndex: "rxNote", key: "rxNote" },
                ]}
                dataSource={entry.prescriptions}
                pagination={false}
              />
            </Card>
          ))}
        </>
      )}
    </>
  );
};

export default SpectaclePrescriptionReport;
