import React, { useState } from "react";
import { Card, Row, Col, Menu, Divider, Button, Table, Form, DatePicker, Input, Radio } from "antd";

const { RangePicker } = DatePicker;

const BillingSummaryPage: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState("provider");
  const [reportData, setReportData] = useState<any[]>([]);

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const handleFinish = (values: any) => {
    console.log("Form Values:", values);

    // Dummy data for table
    setReportData([
      {
        key: 1,
        opt: "John Doe",
        consults: 5,
        spec: "$100",
        frames: "$200",
        lenses: "$150",
        CLs: "$80",
        sundries: "$20",
        misc: "$50",
        noOfFrames: 10,
        totalSales: "$600",
        gst: "$60",
        saleBy: "Jane Smith",
        hour: "09:00 - 10:00",
      },
    ]);
  };

  const getColumns = () => {
    switch (selectedKey) {
      case "provider":
        return [
          { title: "Opt", dataIndex: "opt", key: "opt" },
          { title: "Consults", dataIndex: "consults", key: "consults" },
          { title: "Spec", dataIndex: "spec", key: "spec" },
          { title: "Frames", dataIndex: "frames", key: "frames" },
          { title: "Lenses", dataIndex: "lenses", key: "lenses" },
          { title: "CLs", dataIndex: "CLs", key: "CLs" },
          { title: "Sundries", dataIndex: "sundries", key: "sundries" },
          { title: "Misc", dataIndex: "misc", key: "misc" },
          { title: "No of Frames", dataIndex: "noOfFrames", key: "noOfFrames" },
          { title: "Total Sales", dataIndex: "totalSales", key: "totalSales" },
          { title: "GST", dataIndex: "gst", key: "gst" },
        ];
      case "sale-by":
        return [
          { title: "Sale By", dataIndex: "saleBy", key: "saleBy" },
          { title: "Consults", dataIndex: "consults", key: "consults" },
          { title: "Spec", dataIndex: "spec", key: "spec" },
          { title: "Frames", dataIndex: "frames", key: "frames" },
          { title: "Lenses", dataIndex: "lenses", key: "lenses" },
          { title: "CLs", dataIndex: "CLs", key: "CLs" },
          { title: "Sundries", dataIndex: "sundries", key: "sundries" },
          { title: "Misc", dataIndex: "misc", key: "misc" },
          { title: "No of Frames", dataIndex: "noOfFrames", key: "noOfFrames" },
          { title: "Total Sales", dataIndex: "totalSales", key: "totalSales" },
          { title: "GST", dataIndex: "gst", key: "gst" },
        ];
      case "time":
        return [
          { title: "Hour", dataIndex: "hour", key: "hour" },
          { title: "Consults", dataIndex: "consults", key: "consults" },
          { title: "Spec", dataIndex: "spec", key: "spec" },
          { title: "Frames", dataIndex: "frames", key: "frames" },
          { title: "Lenses", dataIndex: "lenses", key: "lenses" },
          { title: "CLs", dataIndex: "CLs", key: "CLs" },
          { title: "Sundries", dataIndex: "sundries", key: "sundries" },
          { title: "Misc", dataIndex: "misc", key: "misc" },
          { title: "No of Frames", dataIndex: "noOfFrames", key: "noOfFrames" },
          { title: "Total Sales", dataIndex: "totalSales", key: "totalSales" },
          { title: "GST", dataIndex: "gst", key: "gst" },
        ];
      default:
        return [];
    }
  };

  const renderContent = () => (
    <Card style={{ minHeight: "calc(100vh - 138px)", overflowY: "auto" }}>
      <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
        {`Billing Summary - ${selectedKey.replace(/-/g, " ")}`}
      </Divider>
      <Form layout="vertical" onFinish={handleFinish}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Required" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "Required" }]}
            >
              <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Optometrist"
              name="optometrist"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Enter Optometrist Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Report Destination" name="reportDestination">
              <Radio.Group>
                <Radio value="screen">To Screen</Radio>
                <Radio value="printer">To Printer</Radio>
                <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <div>
            <Button>Reset</Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "34px", marginLeft: "12px" }}
            >
              Generate Report
            </Button>
          </div>
        </Row>
      </Form>
      {reportData.length > 0 && (
        <>
          <Divider orientation="left" orientationMargin="0" style={{ marginTop: "24px" }}>
            Report Data
          </Divider>
          <Table columns={getColumns()} dataSource={reportData} pagination={false} />
        </>
      )}
    </Card>
  );

  return (
    <Row gutter={24} style={{ height: "100vh" }}>
      <Col span={8}>
        <Card
          style={{
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
            height: "calc(100vh - 138px)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            style={{ border: "none" }}
          >
            <Menu.Item key="provider">Billing Summary - Provider</Menu.Item>
            <Menu.Item key="sale-by">Billing Summary - Sale By</Menu.Item>
            <Menu.Item key="time">Billing Summary - Time</Menu.Item>
          </Menu>
        </Card>
      </Col>
      <Col span={16}>{renderContent()}</Col>
    </Row>
  );
};

export default BillingSummaryPage;
