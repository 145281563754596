import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, DatePicker, Button, Radio, Descriptions } from "antd";
import dayjs from "dayjs";

const PatientNewOldReport: React.FC = () => {
  const [reportData, setReportData] = useState({
    oldPatients: 120,
    newPatients: 80,
    totalPatients: 200,
  });
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setDateRange([
      dayjs(values.startDate).format("DD-MM-YYYY"),
      dayjs(values.endDate).format("DD-MM-YYYY"),
    ]);

    // Dummy data for demonstration purposes
    setReportData({
      oldPatients: 150,
      newPatients: 100,
      totalPatients: 250,
    });
  };

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Patient New/Old Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {dateRange && reportData && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Patient New/Old Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Descriptions bordered column={3} layout="vertical">
            <Descriptions.Item label="Number of Old Patients">
              {reportData.oldPatients}
            </Descriptions.Item>
            <Descriptions.Item label="Number of New Patients">
              {reportData.newPatients}
            </Descriptions.Item>
            <Descriptions.Item label="Total Number of Patients">
              {reportData.totalPatients}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </>
  );
};

export default PatientNewOldReport;
