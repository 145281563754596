import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Input, Button, Radio, DatePicker, Table } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const ConsultationItemReport: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const handleFinish = (values: any) => {
    console.log("Report values: ", values);
    setDateRange([
      dayjs(values.startDate).format("DD-MM-YYYY"),
      dayjs(values.endDate).format("DD-MM-YYYY"),
    ]);

    // Dummy data for demonstration purposes
    setReportData([
      {
        key: 1,
        code: "ITEM001",
        jan: 100,
        feb: 120,
        mar: 110,
        apr: 130,
        may: 150,
        jun: 140,
        jul: 160,
        aug: 170,
        sep: 180,
        oct: 190,
        nov: 200,
        dec: 210,
        total: 1860,
      },
      {
        key: 2,
        code: "ITEM002",
        jan: 90,
        feb: 80,
        mar: 85,
        apr: 100,
        may: 95,
        jun: 105,
        jul: 110,
        aug: 115,
        sep: 120,
        oct: 130,
        nov: 125,
        dec: 135,
        total: 1320,
      },
      // Add more rows as necessary
    ]);
  };

  const columns = [
    { title: "Code", dataIndex: "code", key: "code" },
    { title: "Jan", dataIndex: "jan", key: "jan" },
    { title: "Feb", dataIndex: "feb", key: "feb" },
    { title: "Mar", dataIndex: "mar", key: "mar" },
    { title: "Apr", dataIndex: "apr", key: "apr" },
    { title: "May", dataIndex: "may", key: "may" },
    { title: "Jun", dataIndex: "jun", key: "jun" },
    { title: "Jul", dataIndex: "jul", key: "jul" },
    { title: "Aug", dataIndex: "aug", key: "aug" },
    { title: "Sep", dataIndex: "sep", key: "sep" },
    { title: "Oct", dataIndex: "oct", key: "oct" },
    { title: "Nov", dataIndex: "nov", key: "nov" },
    { title: "Dec", dataIndex: "dec", key: "dec" },
    { title: "Total", dataIndex: "total", key: "total" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Consultation Item Report
          </Divider>

          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="Report Destination" name="reportDestination">
                <Radio.Group>
                  <Radio value="screen">To Screen</Radio>
                  <Radio value="printer">To Printer</Radio>
                  <Radio value="printerWithPrompt">To Printer with Prompt</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Consultation Item Report {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default ConsultationItemReport;
