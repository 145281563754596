import React, { useState } from "react";
import { Card, Divider, Row, Col, Form, Button, DatePicker, Table } from "antd";
import dayjs from "dayjs";

const ConsultationReportWithoutBilling: React.FC = () => {
  const [reportData, setReportData] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  // Handle form submission
  const handleFinish = (values: any) => {
    console.log("Form Values:", values);

    // Set the formatted date range
    setDateRange([
      dayjs(values?.startDate)?.format("DD-MM-YYYY"),
      dayjs(values?.endDate)?.format("DD-MM-YYYY"),
    ]);

    // Dummy data for Consultation Report Without Billing
    setReportData([
      {
        key: 1,
        date: "10-10-2024",
        name: "John Doe",
        patientRef: "PAT12345",
      },
      {
        key: 2,
        date: "11-10-2024",
        name: "Jane Smith",
        patientRef: "PAT67890",
      },
      {
        key: 3,
        date: "12-10-2024",
        name: "Alice Brown",
        patientRef: "PAT54321",
      },
    ]);
  };

  // Define the table columns
  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Patient Reference", dataIndex: "patientRef", key: "patientRef" },
  ];

  return (
    <>
      <Card
        style={{
          boxShadow: "0px 8px 40px -10px #00000014",
          borderRadius: "4px",
        }}
      >
        <Form layout="vertical" onFinish={handleFinish}>
          <Divider orientation="left" orientationMargin="0" style={{ marginBottom: "34px" }}>
            Consultation Report Without Billing
          </Divider>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Date"
                name="endDate"
                rules={[{ required: true, message: "Required" }]}
              >
                <DatePicker format="DD-MM-YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={"end"}>
            <div>
              <Button>Reset</Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "34px", marginLeft: "12px" }}
              >
                Generate Report
              </Button>
            </div>
          </Row>
        </Form>
      </Card>

      {reportData.length > 0 && (
        <Card
          style={{
            marginTop: "24px",
            boxShadow: "0px 8px 40px -10px #00000014",
            borderRadius: "4px",
          }}
        >
          <Divider orientation="left" orientationMargin="0">
            Consultation Report Without Billing {dateRange?.[0]} To {dateRange?.[1]}
          </Divider>
          <Table columns={columns} dataSource={reportData} pagination={false} />
        </Card>
      )}
    </>
  );
};

export default ConsultationReportWithoutBilling;
