import React, { useState } from "react";
import { Card, Row, Col, Input, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routeConstants";

const PatientReportBySuburbPage: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleSearch = (value: string) => {
    setSearchText(value);
    // Add logic to filter the table based on the searchText
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
    },
    {
      title: "Suburb",
      dataIndex: "suburb",
      key: "suburb",
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      key: "postcode",
    },
    {
      title: "No. of Patients",
      dataIndex: "noOfPatients",
      key: "noOfPatients",
    },
  ];

  const data = [
    {
      key: "1",
      sn: "1",
      suburb: "Suburb A",
      postcode: "1234",
      noOfPatients: "50",
    },
    {
      key: "2",
      sn: "2",
      suburb: "Suburb B",
      postcode: "5678",
      noOfPatients: "30",
    },
    // Add more data as needed
  ];

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <Card
      style={{
        boxShadow: "0px 8px 40px -10px #00000014",
        borderRadius: "4px",
      }}
    >
      <Row gutter={24} style={{ marginBottom: "20px" }}>
        <Col span={8}>
          <Input.Search placeholder="Search" enterButton onSearch={handleSearch} />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 10 }}
        rowKey="key"
      />
    </Card>
  );
};

export default PatientReportBySuburbPage;
